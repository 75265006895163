import React from "react"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import CategoryItems from "../../components/CategoryItems"
import CollectionTemplate from "../../templates/CollectionTemplate"

const BespokePage = () => {
  return (
    <CollectionTemplate
      collectionTitle="BESPOKE"
      classExtra="bespokecollectionpage"
    >
      <SEO
        title="Bespoke"
        description={`Design your own engagement ring, earrings, pendant, bracelet. Gold and ethical, mining free gemstones (moissanite, morganite, lab diamond, sapphire). Vintage, solitaire, art deco, cluster.`}
      />
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection">
          all
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/rings">
          rings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/decollete">
          decollete
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/earrings">
          earrings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/homme">
          homme
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEOBespoke" />
        </div> */}
        <div className="SEOText">
          One off designs, just for you! This is where the magic happens. At
          Dianne Rowe, we pride ourselves on creating designs inspired by the
          things closest to your heart, be it a moment, memory or milestone. We
          can't wait to turn your dream design into a reality!
        </div>
      </div>
      <div id="scroll" className="storewrap">
        <CategoryItems category="bespoke" />
      </div>
    </CollectionTemplate>
  )
}

export default BespokePage
